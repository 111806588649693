<template> 
  <div class="edp-backend">
    <edp-backend-user />

    <section class="edp-backend-middle">
      <edp-backend-menu />

      <!-- content -->
      <section class="edp-backend-content">
        <div class="edp-backend-content-out">
          <mainTitle> </mainTitle>

          <section class="edp-backend-common-table">
            <div class="table-headControlLine">
              <div>
                <el-button type="primary" size="small" @click="onShow(1)">
                  {{ $t("edpBackendEntry.tableControl1") }}</el-button
                >
              </div>
            </div>

            <el-table
              stripe
              :data="pageList"
              style="width: 100%"
              v-loading="loading"
            >
              <el-table-column
                fixed
                prop="modCnName"
                :label="$t('edpBackendEntry.tableTite1')"
                width="200"
              >
              </el-table-column>

              <el-table-column :label="$t('edpBackendEntry.tableTite2')">
                <template slot-scope="scope">
                  <p v-html="scope.row.modEnName"></p>
                </template>
              </el-table-column>

              <el-table-column :label="$t('edpBackendEntry.tableTite3')">
                <template slot-scope="scope">
                  <img
                    :src="scope.row.modImgPrefix + scope.row.modImgUrl"
                    width="120"
                    height="100"
                  />
                </template>
              </el-table-column>

              <el-table-column
                prop="modLinkUrl"
                :label="$t('edpBackendEntry.tableTite4')"
              ></el-table-column>

              <el-table-column
                prop="isEnabled"
                :label="$t('edpBackendEntry.tableTite5')"
              >
                <template slot-scope="scope">
                  <el-tag
                    :type="scope.row.isEnabled === true ? 'success' : 'danger'"
                    disable-transitions
                    >{{ scope.row.isEnabled == true ? "启用" : "禁用" }}</el-tag
                  >
                </template>
              </el-table-column>

              <el-table-column
                prop="createTime"
                :label="$t('edpBackendEntry.tableTite6')"
              ></el-table-column>

              <el-table-column
                :label="$t('edpBackendCommon.operate')"
                fixed="right"
                width="110"
              >
                <template slot-scope="scope">
                  <el-button type="text" @click="onShow(2, scope.row)">
                    {{ $t("edpBackendCommon.edit") }}</el-button
                  >
                  <el-button type="text" @click="deleteRow(scope.row)">
                    {{ $t("edpBackendCommon.delete") }}</el-button
                  >
                </template>
              </el-table-column>
            </el-table>

            <el-pagination
              background
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="pageTotal"
            >
            </el-pagination>
          </section>
        </div>
      </section>
      <!-- content end -->
    </section>
    <el-dialog
      :title="dialogTitle"
      width="32%"
      :visible.sync="editShow"
      :before-close="onClose"
    >
      <el-form :model="form" label-width="150px" ref="entryForm">
        <el-form-item
          :label="$t('edpBackendEntry.formTit1')"
          :rules="[{ required: true, message: '不能为空' }]"
        >
          <el-input
            v-model="form.modCnName"
            maxlength="20"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('edpBackendEntry.tableTite2')"
          :rules="[{ required: true, message: '不能为空' }]"
        >
          <el-input v-model="form.modEnName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item :label="$t('edpBackendEntry.formTit2')">
          <UploadImage
            :limit="1"
            :imgList="fileImgList"
            @onSuccessFiles="onSuccessImgFiles"
          />
        </el-form-item>
        <el-form-item
          :label="$t('edpBackendEntry.formTit3')"
          :rules="[{ required: true, message: '不能为空' }]"
        >
          <el-switch v-model="form.isEnabled"></el-switch>
        </el-form-item>
        <el-form-item :label="$t('edpBackendEntry.formTit4')">
          <el-input
            v-model.number="form.modSorted"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('edpBackendEntry.tableTite4')"
          :rules="[{ required: true, message: '不能为空' }]"
        >
          <el-input v-model="form.modLinkUrl" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="editShow = false">{{
          $t("edpBackendCommon.canale")
        }}</el-button>
        <el-button type="primary" @click="onSave('entryForm')">{{
          $t("edpBackendCommon.save")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import UploadImage from "../../components/upload/entryUpload.vue";
export default {
  name: "backendEntry",
  components: {
    UploadImage,
  },
  data() {
    return {
      fileImgList: [],
      pageList: [],
      currentPage: 1,
      pageSize: 10,
      pageTotal: 0,
      loading: false,
      editShow: false,
      dialogTitle: this.$t("edpBackendCommon.add"),
      form: {
        id: null,
        modEnName: "",
        modCnName: "",
        modImgUrl: "",
        modLinkUrl: "",
        modSorted: 0,
        isEnabled: false,
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    ...mapActions({
      moduleList: "user/moduleList",
      addModule: "user/addModule",
      delModule: "user/delModule",
      editModule: "user/editModule",
    }),
    // 监听图片上传
    onSuccessImgFiles(files) {
      console.log("onSuccessImgFiles", files);
      this.fileImgList = files;
      if (this.fileImgList.length) {
        this.form.modImgUrl = files[0].name;
      }
    },
    async getList() {
      let params = { current: this.currentPage, size: this.pageSize };
      let res = await this.moduleList(params);
      console.log(res);
      this.pageList = res.data.records;
      this.currentPage = res.data.current;
      this.pageTotal = res.data.total;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
    },
    onShow(val, data) {
      if (val == 1) {
        this.dialogTitle = this.$t("edpBackendCommon.add");
        this.fileImgList = [];
      } else {
        console.log(data);
        this.dialogTitle = this.$t("edpBackendCommon.edit");
        this.form = JSON.parse(JSON.stringify(data));
        let urls = this.form.modImgPrefix + this.form.modImgUrl;
        this.fileImgList = [
          { uid: this.form.modImgUrl, url: urls, name: this.form.modImgUrl },
        ];
      }
      this.editShow = true;
    },
    onClose(done) {
      for (var key in this.form) {
        this.form[key] = key === "modSorted" ? 0 : "";
      }
      done();
    },
    async onSave(formName) {
      let res = {};
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.form.id) {
            res = await this.editModule(this.form);
          } else {
            res = await this.addModule(this.form);
          }

          if (res.success) {
            this.editShow = false;
            for (var key in this.form) {
              this.form[key] = key === "modSorted" ? 0 : "";
            }
            this.getList();
            this.$message({
              type: "success",
              message: this.$t("edpBackendCommon.actionSuccess"),
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async deleteRow(val) {
      this.$confirm("入口删除后，用户将无法看到入口，请确认是否删除？", "删除")
        .then(async (_) => {
          let res = await this.delModule(val);
          if (res.success) {
            this.getList();
            this.$message({
              type: "success",
              message: this.$t("edpBackendCommon.actionSuccess"),
            });
          }
        })
        .catch((_) => {});
    },
    handleAvatarSuccess(res, file) {
      this.form.imageUrl = URL.createObjectURL(file.raw);
    },
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";
</style>
<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
</style>
